var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_payable_mutation_report") } },
    [
      _c(
        "a-form-model",
        {
          ref: "debtCardForm",
          attrs: {
            model: _vm.formModel,
            rules: _vm.formRules,
            layout: "horizontal",
            "label-align": "left",
            "wrapper-col": { span: 12 },
            "label-col": { span: 8 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_invoice_date"), prop: "date" }
                    },
                    [
                      _c("a-range-picker", {
                        staticClass: "w-100",
                        attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                        model: {
                          value: _vm.formModel.date,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "date", $$v)
                          },
                          expression: "formModel.date"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_branch"), prop: "branchId" }
                    },
                    [
                      _c("SelectBranch", {
                        on: { "update:meta": _vm.onChangeBranch },
                        model: {
                          value: _vm.formModel.branchId,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "branchId", $$v)
                          },
                          expression: "formModel.branchId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_supplier"),
                        prop: "supplierId"
                      }
                    },
                    [
                      _c("SelectSupplier", {
                        on: { "update:meta": _vm.onChangeSupplier },
                        model: {
                          value: _vm.formModel.supplierId,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "supplierId", $$v)
                          },
                          expression: "formModel.supplierId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_payment_date"),
                        prop: "paymentDate"
                      }
                    },
                    [
                      _c("a-range-picker", {
                        staticClass: "w-100",
                        attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                        model: {
                          value: _vm.formModel.paymentDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "paymentDate", $$v)
                          },
                          expression: "formModel.paymentDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_invoice_type"),
                        prop: "invoiceType"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "show-search": "",
                            "allow-clear": "",
                            loading: _vm.loading.invoiceType,
                            "option-filter-prop": "children",
                            "filter-option": _vm.useLocalFilter
                          },
                          model: {
                            value: _vm.formModel.invoiceType,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "invoiceType", $$v)
                            },
                            expression: "formModel.invoiceType"
                          }
                        },
                        _vm._l(_vm.optInvoiceType, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(" " + _vm._s(item.label) + " ")]
                                ),
                                _vm._v(" " + _vm._s(item.label) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_invoice_status"),
                        prop: "status"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "show-search": "",
                            "allow-clear": "",
                            loading: _vm.loading.status,
                            "option-filter-prop": "children",
                            "filter-option": _vm.useLocalFilter
                          },
                          model: {
                            value: _vm.formModel.status,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "status", $$v)
                            },
                            expression: "formModel.status"
                          }
                        },
                        _vm._l(_vm.optStatus, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(" " + _vm._s(item.label) + " ")]
                                ),
                                _vm._v(" " + _vm._s(item.label) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_currency"),
                        prop: "currencyId"
                      }
                    },
                    [
                      _c("SelectCurrency", {
                        on: { "update:meta": _vm.onChangeCurrency },
                        model: {
                          value: _vm.formModel.currencyId,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "currencyId", $$v)
                          },
                          expression: "formModel.currencyId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_invoice_number"),
                        prop: "invoiceNumber"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "show-search": "",
                            "allow-clear": "",
                            loading: _vm.loading.invoice,
                            "filter-option": false
                          },
                          on: { search: _vm.onSearchInvoice },
                          model: {
                            value: _vm.formModel.invoiceNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "invoiceNumber", $$v)
                            },
                            expression: "formModel.invoiceNumber"
                          }
                        },
                        _vm._l(_vm.optInvoiceNumbers, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(" " + _vm._s(item.label) + " ")]
                                ),
                                _vm._v(" " + _vm._s(item.label) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_invoice_supplier_number"),
                        prop: "invoiceSupplierNumber"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "show-search": "",
                            "allow-clear": "",
                            loading: _vm.loading.invoiceSupplier,
                            "filter-option": false
                          },
                          on: { search: _vm.onSearchSupplierInvoice },
                          model: {
                            value: _vm.formModel.invoiceSupplierNumber,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formModel,
                                "invoiceSupplierNumber",
                                $$v
                              )
                            },
                            expression: "formModel.invoiceSupplierNumber"
                          }
                        },
                        _vm._l(_vm.optInvoiceSupplierNumbers, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(" " + _vm._s(item.label) + " ")]
                                ),
                                _vm._v(" " + _vm._s(item.label) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { "html-type": "reset" },
                          on: { click: _vm.resetForm }
                        },
                        [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "html-type": "submit",
                            type: "primary",
                            loading: _vm.loading.find
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("lbl_find")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-table",
        {
          attrs: {
            columns: _vm.columns,
            pagination: {
              showTotal: function(total) {
                return _vm.$t("lbl_total_items", { total: total })
              },
              showSizeChanger: true,
              pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
            },
            "data-source": _vm.dataSource,
            loading: _vm.loading.find,
            scroll: { x: "calc(1500px + 100%)", y: 728 },
            size: "small"
          },
          scopedSlots: _vm._u([
            {
              key: "nullable",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
              }
            },
            {
              key: "currency",
              fn: function(text) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(text)))
                ])
              }
            },
            {
              key: "date",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
              }
            }
          ])
        },
        [
          _c(
            "a-descriptions",
            { attrs: { slot: "footer", size: "small" }, slot: "footer" },
            [
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_supplier") } },
                [_vm._v(" " + _vm._s(_vm.vmInfo.supplierName || "-") + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_bill_to_address") } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.vmInfo.supplierBillToAddress || "-") + " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_total_invoice_outstanding") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.vmInfo.totalInvoiceOutstanding)
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-right mt-2" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading.download },
              on: { click: _vm.handleDownload }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }